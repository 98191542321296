import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import './Header.css'; // assuming you have a Header.css file for styling
import fastStockLogo from '../images/header/fastStockLogo.png';
import fastStockLogoWhite from '../images/header/fastStockLogoWhite.png';
import losMuchachosLogo from '../images/header/losMuchachos.png';
import rustyForterLogo from '../images/header/rusty_logo.png';
import dixieForterLogo from '../images/header/dixie_logo.png';
import { ReactImageTint } from './TintImage';
import constants from '../constants';
import { firebaseLogout } from './Firebase';

const logoSelector = (tenant, substore) => {
    if (tenant == null) return losMuchachosLogo;
    if (tenant === constants.FORTER_TENANT_NAME){
        if (substore === constants.FORTER_SUBSTORE_DIXIE_NAME){
            return dixieForterLogo;
        } else {
            return rustyForterLogo;
        }
        
    } 
    return losMuchachosLogo;
}

const Header = ({tenant, substore, isDarkMode}) => {
    const navigate = useNavigate();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail'));
    const logo = logoSelector(tenant, substore);
    const isTinted = tenant === 'forter';

    const classHeader = isDarkMode ? "header" : "header header-whitemode";
    const fastStockLogoSrc = isDarkMode ? fastStockLogo : fastStockLogoWhite;
    const colorMode = isDarkMode ? '#fff' : '#000';

    const isLogged = userEmail !== null;

    const loginText = isLogged ? 'Logout' : 'Login';

    const toggleDrawer = (isOpen) => {
        setIsDrawerOpen(isOpen);
    };

    const handleSignIn = () => {
        const currentPath = window.location.pathname;
        const newPath = currentPath.substring(0, currentPath.lastIndexOf('/')) + '/auth';
        navigate(newPath);
    }

    const handleSignOut = () => {
        firebaseLogout();
        setUserEmail(null);
    }

    return (
        <div className={classHeader}>
            {isTinted ? <ReactImageTint src={logo} alt="logo" color="#000" /> : 
                <img src={logo} alt="logo" />  }
            {/* TODO : Disabled para forter */}
            {tenant !== constants.FORTER_TENANT_NAME && <>
            
                <div className="menu-icon" onClick={() => toggleDrawer(true)} style={{ color: colorMode }}>
                    &#9776; {/* Icono de menú hamburguesa */}
                </div>
                <Drawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={() => toggleDrawer(false)}
                >
                    <Box
                        sx={{ width: '100%' }}
                        role="presentation"
                        onClick={() => toggleDrawer(false)}
                    >

                        <div className="logoContainer">
                            <img className="fastStockLogo" src={fastStockLogoSrc} alt="logo" />
                        </div>
                        <Divider style={{ borderColor: colorMode }}/>

                        <List>
                            {/* User Icon and Email */}
                            {isLogged && (
                                    <ListItem key={userEmail} disablePadding style={{ color: colorMode }}>
                                        <ListItemButton onClick={null}>
                                        <ListItemIcon style={{ color: colorMode }}>
                                            <AccountCircleIcon />  {/* User icon */}
                                        </ListItemIcon>
                                        <ListItemText primary={userEmail} />  {/* Display email */}
                                        </ListItemButton>
                                        
                                    </ListItem>
                            )}
                            <ListItem key={loginText} disablePadding style={{ color: colorMode }}>
                                <ListItemButton onClick={isLogged ? () => handleSignOut() : () => handleSignIn()}>
                                    <ListItemIcon style={{ color: colorMode }}>
                                        {isLogged ? <LogoutIcon /> : <LoginIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={loginText} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
                </Drawer>
                </>
            }
            
        </div>
    );
}

export default Header;
