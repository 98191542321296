import { initializeApp } from "firebase/app";
import 'firebase/firestore'; // For Cloud Firestore
import { getFirestore, collection } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, EmailAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBV9b_h9cFuyTXzWDslKzYkaO0Hw3ctq14",
  authDomain: "losmuchachos-stock.firebaseapp.com",
  projectId: "losmuchachos-stock",
  storageBucket: "losmuchachos-stock.appspot.com",
  messagingSenderId: "126046936074",
  appId: "1:126046936074:web:ea26301e9e7917a70f53f3"
};

const firebase = initializeApp(firebaseConfig);

const firebaseAuth = getAuth(firebase);
const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.setDefaultLanguage('es');
const emailAuthProvider = new EmailAuthProvider();

// Access the Firestore instance
const firebaseDB = getFirestore(firebase);

const getTenantCollection = (tenant) => {
  console.log(tenant);
  if (tenant == null) return collection(firebaseDB, "geolocations");
  return collection(firebaseDB, tenant)
}


const firebaseAuthUIConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: false,
  // signInSuccessUrl: 'www.google.com',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    {
      provider: googleAuthProvider.providerId,
    },
    {
      provider: emailAuthProvider.providerId,
    }
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: function() {
    // window.location.assign('<your-privacy-policy-url>');
  },
  // Privacy policy url/callback.
  privacyPolicyUrl: function() {
    // window.location.assign('<your-privacy-policy-url>');
  }
};

// Function to handle logout
const firebaseLogout = () => {
  firebaseAuth.signOut().then(() => {
    // Clear user data from localStorage
    localStorage.removeItem('userEmail');
    // Redirect or take other action after logout
  }).catch((error) => {
    console.error("Error logging out: ", error);
  });
};

export { firebaseAuth, getTenantCollection, firebaseDB, firebaseAuthUIConfig, firebaseLogout };